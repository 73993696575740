import React from 'react'

export const ResetPasswordSuccess = () => {
    return (
        <section>
            <div>
                Reset Successfull, Go to Login page and login now !
            </div>
        </section>
    )
}
