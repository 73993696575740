import React from 'react';
import './Samples.css';
import axios from "axios";
import { useEffect } from 'react';

export const Samples = () => {


    // useEffect()

    // axios({
    //     url: "http://localhost:9600/login",
    //     method: "POST",
    //     data: { uid: username, pd: pd },
    // })
    // .then((res) => {
    
    // })
    // .catch((err) => {
    
    // });
    


    return (

        <section>
            <div>
               
            </div>
        </section>




        // <div>
        //     <div className='container mb-5' style={{marginTop: '20%'}}>
        //         <div className='row'>
        //             <div className='col-lg-10 col-md-8 col-sm-6 col-xs-6' style={{border: '1px solid red'}}>column with 8 grids</div>
        //             <div className='col-lg-2 col-md-4 col-sm-6 col-xs-6' style={{border: '1px solid red'}}>column with 4 grids</div>
        //         </div>
        //     </div>

        // </div>

    )
}